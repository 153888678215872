import React from 'react';
import Header from '../components/Header';
import { Box, Grid, Paper, Typography, Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

const Login = () => {
  let url = '';
  if (process.env.NODE_ENV === 'development') {
    url =
      'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Flogin%2Fcallback&client_id=424544222380-iesv4spv0ecinae6ouecust7bfek3va9.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email';
  } else {
    url =
      'https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https%3A%2F%2Fcameronlevine.com%2Flogin%2Fcallback&client_id=424544222380-iesv4spv0ecinae6ouecust7bfek3va9.apps.googleusercontent.com&access_type=offline&response_type=code&prompt=consent&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email';
  }
  return (
    <Box>
      <Header title={'Login'} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '60vh' }}
      >
        <Paper
          elevation={10}
          sx={{ padding: 4, margin: 4, backgroundColor: '#212121' }}
        >
          <Typography variant="h4" align="center" sx={{ color: 'white' }}>
            Login Using Google
          </Typography>
          <Box textAlign="center" marginTop={2}>
            <Button
              onClick={() => {
                window.location.replace(url);
              }}
              variant="contained"
              startIcon={<GoogleIcon />}
              size="large"
            >
              Login
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Box>
  );
};

export default Login;

import Header from '../components/Header';
import { Box, Grid, Paper, Typography } from '@mui/material';

const Error = () => {
  return (
    <Box>
      <Header title={'Error'} />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '60vh' }}
      >
        <Paper
          elevation={10}
          sx={{ padding: 4, margin: 4, backgroundColor: '#212121' }}
        >
          <Typography variant="h4" align="center" sx={{ color: 'white' }}>
            Unknown Error
          </Typography>
          <Typography variant="body1" align="center" sx={{ color: 'white' }}>
            Unfortunately there was an unknown error. If this error continues
            please contact Cameron.
          </Typography>
        </Paper>
      </Grid>
    </Box>
  );
};

export default Error;

import React from 'react';
import './app.css';
import { Route, Routes } from 'react-router-dom';
import Callback from './pages/Callback';
import RecentlyWatched from './pages/RecentlyWatched';
// import DownloadMedia from './pages/DownloadMedia';
// import RequestDownload from './pages/RequestDownload';
import Watch from './pages/Watch';
import Home from './pages/Home';
import Login from './pages/Login';
import Nav from './components/Nav';
import Media from './pages/Media';
import Error from './pages/Error';
import WatchDetails from './pages/WatchDetails';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/callback" element={<Callback />} />
        <Route path="/media" element={<Media />} />
        <Route path="/media/watched" element={<RecentlyWatched />} />
        {/* <Route path="/media/download" element={<DownloadMedia />} />
        <Route path="/media/download/complete" element={<RequestDownload />} /> */}
        <Route path="/media/watch" element={<Watch />} />
        <Route path="/media/watch/:id" element={<WatchDetails />} />
        <Route path="/error" element={<Error />} />
      </Routes>
      <Nav />
    </>
  );
};

export default App;

import { useState, useEffect } from 'react';
import axios from 'axios';

export const useAxios = (config, initialValue) => {
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const request = {
    method: config.method || 'GET',
    url: config.url,
    data: config.body ? config.body : null,
  };

  useEffect(() => {
    (async () => {
      try {
        console.log(request);
        const res = await axios(request);
        const data = res.data;
        setData(data);
      } catch (err) {
        setError(err.response);
      } finally {
        setLoading(false);
      }
    })();
    return () => {};
  }, []);
  return { loading, data, error };
};

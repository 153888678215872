import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import TvIcon from '@mui/icons-material/Tv';
import {
  BottomNavigation,
  BottomNavigationAction,
  styled,
} from '@mui/material';

const StyledBottomNavigationAction = styled(BottomNavigationAction)(`
  color: white;
  &.Mui-selected {
    color: 'rgba(0, 223, 252)';
  }
`);

const Nav = () => {
  const fullPath = useLocation();
  let pathname = fullPath.toString().split('/')[0];
  const [value, setValue] = React.useState(pathname);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <BottomNavigation
      sx={{
        width: '100%',
        position: 'fixed',
        bottom: 0,
        justifyContent: 'space-around',
        background: '#171717',
        height: '60px',
      }}
      value={value}
      onChange={handleChange}
    >
      <StyledBottomNavigationAction
        label="Home"
        icon={<HomeIcon />}
        component={Link}
        to="/"
      />
      <StyledBottomNavigationAction
        label="Media"
        icon={<TvIcon />}
        component={Link}
        to="/media"
      />
      <StyledBottomNavigationAction
        label="Profile"
        icon={<PersonIcon />}
        component={Link}
        to="/login"
      />
    </BottomNavigation>
  );
};

export default Nav;

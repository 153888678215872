import Header from '../components/Header';
import { Link } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
import LiveTvIcon from '@mui/icons-material/LiveTv';

const Media = () => {
  return (
    <Box>
      <Header title={'Media'} />
      <Grid
        container
        spacing={0}
        direction="column"
        justifyContent="center"
        style={{ minHeight: '60vh' }}
      >
        <Link to="/media/watch" style={{ textDecoration: 'none' }}>
          <Paper
            elevation={10}
            sx={{
              padding: 2,
              margin: 2,
              marginLeft: 4,
              marginRight: 4,
              backgroundColor: '#212121',
            }}
          >
            <Typography variant="h4" align="center" sx={{ color: 'white' }}>
              Watch
            </Typography>
            <Box textAlign="center" marginTop={2}>
              <LiveTvIcon sx={{ color: 'white', fontSize: '40px' }} />
            </Box>
          </Paper>
        </Link>
        {/* <Link to="/media/download" style={{ textDecoration: 'none' }}>
          <Paper
            elevation={10}
            sx={{
              padding: 2,
              margin: 2,
              marginLeft: 4,
              marginRight: 4,
              backgroundColor: '#212121',
            }}
          >
            <Typography variant="h4" align="center" sx={{ color: 'white' }}>
              Download
            </Typography>
            <Box textAlign="center" marginTop={2}>
              <FileDownloadIcon sx={{ color: 'white', fontSize: '40px' }} />
            </Box>
          </Paper>
        </Link> */}
        <Link to="/media/watched" style={{ textDecoration: 'none' }}>
          <Paper
            elevation={10}
            sx={{
              padding: 2,
              margin: 2,
              marginLeft: 4,
              marginRight: 4,
              backgroundColor: '#212121',
            }}
          >
            <Typography variant="h4" align="center" sx={{ color: 'white' }}>
              Recently Watched
            </Typography>
            <Box textAlign="center" marginTop={2}>
              <HistoryIcon sx={{ color: 'white', fontSize: '40px' }} />
            </Box>
          </Paper>
        </Link>
      </Grid>
    </Box>
  );
};

export default Media;

import React from 'react';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  tableCellClasses,
  styled,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: '#171717',
    color: theme.palette.common.white,
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const RecentlyWatched = (data) => {
  const watched = data.recentlyWatched;
  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableBody>
          {watched.map((o) => {
            if (o.show) {
              return (
                <StyledTableRow key={o.episodeTitle}>
                  <StyledTableCell align="center">{`${o.show} - Season ${o.season} Episode ${o.episodeNumber} - ${o.episodeTitle} - Watch on: ${o.watchedAt}`}</StyledTableCell>
                </StyledTableRow>
              );
            } else {
              return (
                <StyledTableRow key={o.movieTitle}>
                  <StyledTableCell align="center">{`${o.movieTitle} (${o.movieYear}) - Watch on: ${o.watchedAt}`}</StyledTableCell>
                </StyledTableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default RecentlyWatched;

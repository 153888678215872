import React from 'react';
import Header from '../components/Header';
import LoadingAnimation from '../components/LoadingAnimation';
import { useSearchParams } from 'react-router-dom';
import { useAxios } from '../lib/useAxios';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  let config = { method: 'POST', url: '/api/v1/login', body: { code: code } };
  const { loading, error } = useAxios(config, []);
  if (error) {
    navigate('/error');
  }

  return (
    <div>
      <Header title={'Login'} />
      {loading ? <LoadingAnimation /> : navigate('/')}
    </div>
  );
};

export default Callback;

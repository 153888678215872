import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActions,
  Button,
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FolderIcon from '@mui/icons-material/Folder';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import axios from 'axios';

const MediaCard = ({ data }) => {
  const [deleteId, setDeleteId] = useState('');
  let deleteConfig = {
    method: 'POST',
    url: '/api/v1/media/deleteFile',
  };
  const navigate = useNavigate();

  useEffect(() => {
    const deleteFile = async () => {
      deleteConfig.data = { fileId: deleteId };
      try {
        if (deleteId !== '') {
          console.log('deleting:', deleteId);
          await axios(deleteConfig);
          window.location.reload();
        }
      } catch (err) {
        if (err.response.status === 401) {
          navigate('/login');
        } else if (err) {
          navigate('/error');
        }
      }
    };
    deleteFile();
  }, [deleteId]);

  const handleDeleteClick = async (id) => {
    setDeleteId(id);
  };

  return (
    <Box>
      <Card sx={{ backgroundColor: '#212121' }}>
        {data.parsed.poster ? (
          <CardMedia
            component={'img'}
            height={'200'}
            width={'100%'}
            image={data.parsed.poster}
            alt={'image'}
          />
        ) : (
          ''
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="body1"
            component="div"
            color={'white'}
          >
            {data.parsed.title}
          </Typography>
          <Typography variant="body2" color={'#878787'}>
            {data.parsed.season
              ? `Season: ${data.parsed.season} | ${
                  data.parsed.episode ? `Episode: ${data.parsed.episode} |` : ''
                } ${data.parsed.year}`
              : `${data.parsed.year}`}
          </Typography>
        </CardContent>
        <CardActions sx={{ align: 'center' }}>
          <Button
            size="small"
            onClick={() => {
              handleDeleteClick(data.id);
            }}
          >
            <DeleteForeverIcon sx={{ color: 'red' }} />
          </Button>
          {(() => {
            if (data.mp4_stream_url) {
              return (
                <Button
                  onClick={() => {
                    window.location.replace(`${data.mp4_stream_url}`);
                  }}
                  size="small"
                >
                  <PlayCircleIcon sx={{ color: 'green' }} />
                </Button>
              );
            } else if (data.stream_url) {
              return (
                <Button
                  onClick={() => {
                    window.location.replace(`${data.stream_url}`);
                  }}
                  size="small"
                >
                  <PlayCircleIcon sx={{ color: 'green' }} />
                </Button>
              );
            } else {
              return (
                <Button
                  size="small"
                  onClick={() => {
                    navigate(`/media/watch/${data.id}`);
                  }}
                >
                  <FolderIcon sx={{ color: '#2c5fc7' }} />
                </Button>
              );
            }
          })()}
        </CardActions>
      </Card>
    </Box>
  );
};

export default MediaCard;

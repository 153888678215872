import React from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation';
import { Box, Grid } from '@mui/material';
import { useAxios } from '../lib/useAxios';
import MediaCard from '../components/MediaCard';

const Watch = () => {
  const navigate = useNavigate();
  let config = { method: 'GET', url: '/api/v1/media/getFilesParsed' };
  const { data, loading, error } = useAxios(config, []);
  if (error?.status === 401) {
    navigate('/login');
  } else if (error) {
    navigate('/error');
  }

  console.log(data);
  return (
    <Box pb={5}>
      <Header title={'Watch'} />
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Grid container p={4} spacing={2}>
          {data.map((o) => {
            return (
              <Grid item key={o.id} xs={12} sm={6} md={3}>
                <MediaCard data={o} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Box>
  );
};

export default Watch;

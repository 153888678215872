import React from 'react';
import Header from '../components/Header';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation';
import { Box, Paper, Typography, Button } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { useAxios } from '../lib/useAxios';

const WatchDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  let id = params.id;
  let config = { method: 'GET', url: `/api/v1/media/getFile/${id}` };
  const { data, loading, error } = useAxios(config, []);
  if (error?.status === 401) {
    navigate('/login');
  } else if (error) {
    navigate('/error');
  }

  console.log(data);

  return (
    <Box pb={5}>
      <Header title={'Watch'} />
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Box>
          {data.map((o) => {
            return (
              <Paper
                elevation={10}
                sx={{
                  padding: 2,
                  margin: 4,
                  marginLeft: 4,
                  marginRight: 4,
                  backgroundColor: '#212121',
                }}
              >
                <Typography
                  variant="body1"
                  align="center"
                  sx={{ color: 'white', overflowWrap: 'break-word' }}
                  pb={2}
                >
                  {o.name}
                </Typography>
                <Box
                  component="img"
                  sx={{
                    height: '100%',
                    width: '100%',
                  }}
                  alt={o.name}
                  src={o.screenshot}
                  align="center"
                  pb={2}
                />
                <Box textAlign="center">
                  {(() => {
                    if (o.mp4_stream_url) {
                      return (
                        <Button
                          onClick={() => {
                            window.location.replace(`${o.mp4_stream_url}`);
                          }}
                          variant="contained"
                          startIcon={<PlayCircleIcon />}
                          size="large"
                        >
                          Play
                        </Button>
                      );
                    } else {
                      return (
                        <Button
                          onClick={() => {
                            window.location.replace(`${o.stream_url}`);
                          }}
                          variant="contained"
                          startIcon={<PlayCircleIcon />}
                          size="large"
                        >
                          Play
                        </Button>
                      );
                    }
                  })()}
                </Box>
              </Paper>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default WatchDetails;

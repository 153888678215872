import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, styled, Box, Typography } from '@mui/material';
import Logo from '../assets/logo.png';

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  color: 'white',
  background: '#171717',
  alignItems: 'center',
  height: '60px',
});

const Header = (props) => {
  return (
    <AppBar position="sticky">
      <StyledToolbar variant="dense">
        <Box sx={{ marginRight: 2 }}>
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" className="logo" />
            </div>
          </Link>
        </Box>
        <Typography variant="h4">{props.title}</Typography>
      </StyledToolbar>
    </AppBar>
  );
};

export default Header;

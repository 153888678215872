import React from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation';
import WatchedList from '../components/WatchedList';
import { Box } from '@mui/material';
import { useAxios } from '../lib/useAxios';

const RecentlyWatched = () => {
  const navigate = useNavigate();
  let config = { method: 'GET', url: '/api/v1/media/getWatchingHistory' };
  const { data, loading, error } = useAxios(config, []);
  if (error?.status === 401) {
    navigate('/login');
  } else if (error) {
    navigate('/error');
  }

  return (
    <Box>
      <Header title={'Recently Watched'} />
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Box paddingTop={2} paddingLeft={2} paddingRight={2} paddingBottom={10}>
          <WatchedList recentlyWatched={data} />
        </Box>
      )}
    </Box>
  );
};

export default RecentlyWatched;

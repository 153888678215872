import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="dots-container">
      <div className="dots">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingAnimation;
